.item {
  width: 272px;
  height: 272px;
  background: #000000;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;

  &-wrapper {
    margin: 0 11px 0 11px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #2b282c;
      margin-bottom: 7px;

      .collection_name {
        color: #ffffff;
        font-size: 14px;
        display: flex;
        align-items: center;
        font-family: 'Lexend-Regular', sans-serif;

        .collection-image-s {
          width: 20px;
          //height: 18px;
          margin-right: 8px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .type-pool {
        p {
          font-size: 14px;
        }
      }
    }

    .nfts-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 87px;
      height: 87px;
      margin: 0 auto;

      img {
        width: 100%;
        //height: 100%;
      }
    }

    .nfts-content-card {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: row;
      height: 87px;
      margin-top: 7px;

      &_img {
        //width: 87px;
        height: 76px;
        margin: 8px 5px 0 5px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .container {
      width: 250px;
      height: 120px;
      background: #111122;
      border-radius: 5px;
      margin-top: 13px;
      position: relative;

      &:after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: #000000;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      &-wrapper {
        margin: 0 17px 0 17px;

        .price {
          padding-top: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 18px;

          &-left {
            &-name {
              text-align: left;
              font-size: 12px;
              color: #8e8e8e;
              margin-bottom: 6px;
            }

            &_top_amount {
              display: flex;
              align-items: center;

              img {
                margin-right: 6px;
              }
            }

            &-amount {
              text-align: left;
              font-size: 16px;
              color: #ffffff;
            }
          }

          &-right {
            &-name {
              text-align: right;
              font-size: 11px;
              color: #8e8e8e;
              margin-bottom: 6px;
            }

            &_top_amount {
              display: flex;
              align-items: center;

              img {
                margin-right: 6px;
              }
            }

            &-amount {
              text-align: right;
              font-size: 16px;
              color: #ffffff;

              &.trade {
                text-align: left;
                font-size: 11px;
                color: #8e8e8e;
                margin-bottom: 6px;
              }

              &.n2n{
                margin-top: 20px;
              }
            }
          }
        }


        .delta-balance {
          .delta {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 6px;
            &-p {
              display: flex;
              font-size: 12px;
              font-family: Lexend-Regular, sans-serif;
              color: #8e8e8e;

              p {
                margin-right: 5px;
              }

              &-type {
                font-size: 12px;
                color: #ffffff;

                .item-delta {
                  display: flex;
                  align-items: flex-end;

                  p {
                    margin-right: 3px;
                  }
                }
              }
            }
            &-amount {
              font-size: 14px;
              color: #ffffff;
            }
          }

          .balance {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &-p {
              font-size: 12px;
              font-family: Lexend-Regular, sans-serif;
              color: #8e8e8e;
            }
            &-amount {
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #ffffff;
            }

            &-wax {
              display: flex;
              align-items: center;
              img {
                width: 10px;
                height: 10px;
                margin-right: 3px;
              }
            }
          }
        }
      }
    }

    .pool-info {
      margin: 0 3px;
      &_id {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        .first {
          text-align: left;
          font-size: 11px;
          color: #ffffff;
        }

        .second {
          text-align: right;
          font-size: 11px;
          color: #8e8e8e;
        }
      }

      &_owner {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .first {
          text-align: left;
          font-size: 11px;
          color: #ffffff;
        }

        .second {
          text-align: right;
          font-size: 11px;
          color: #8e8e8e;
        }
      }
    }
  }

  &-type.sell {
    padding: 8px 0;
    text-align: right;
    font-size: 12px;
    letter-spacing: 0;
    color: #c269dd;
  }
  &-type.buy {
    padding: 8px 0;
    text-align: right;
    font-size: 12px;
    letter-spacing: 0;
    color: #7acc66;
  }
  &-type.trade {
    padding: 8px 0;
    text-align: right;
    font-size: 12px;
    letter-spacing: 0;
    color: #e65634;
  }
  &-type.offer {
    padding: 8px 0;
    text-align: right;
    font-size: 12px;
    letter-spacing: 0;
    color: #6EC1FF;
  }
}
